import React from "react";
import ReactDOM from "react-dom";

export const App = (props) => {
  return <>
			<header id="header">
				<h1>VIA FLOR</h1>
				<p>Sitio en construcción<br />Arquitectura del paisaje + Floristería <br/>Av. Patria #2096 tel. 36-40-03-46</p>
			</header>

			{/* <form id="signup-form" method="post" action="#">
				<input type="email" name="email" id="email" placeholder="Email Address" />
				<input type="submit" value="Sign Up" />
			</form> */}
  </>;
};

var mountNode = document.getElementById("root");
ReactDOM.render(<App />, mountNode);
